import Layout from '@/layout/Layout'

function App() {
  return (
    <>
      <Layout />
    </>
  )
}

export default App
