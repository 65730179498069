export const routes = {
  home: '/',
  notFound: '*',
  products: '/product',
  itemDetail: '/product/:itemId',
  categories: '/categories',
  category: '/category',
  categoryDetail: '/category/:categoryId',
  login: '/login',
  register: '/register',
  profile: '/profile',
  profileID: '/profile/:userId',
  dashboard: '/administracion',
  dashboardusers: '/administracion/users',
  dashboardproducts: '/administracion/products',
  dashboardcategories: '/administracion/categories',
  dashboardfeatures: '/administracion/features',
  dashboardorders: '/administracion/orders',
  dashboardsettings: '/administracion/settings',
  search: '/search',
  cart: '/cart',
  contact: '/contact',
  favs: '/favs',
  rentals: '/rentals',
  checkout: '/checkout',
  reservation: '/reservation/:itemId', // Agrega esta línea
}
