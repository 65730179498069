import { ResponsiveBar } from '@nivo/bar'

export function BarChart({ data, ...props }) {
  return (
    <div {...props}>
      <ResponsiveBar
        data={data}
        keys={['cantidad']}
        indexBy='name'
        margin={{ top: 20, right: 0, bottom: 40, left: 40 }}
        padding={0.3}
        colors={['#2563eb']}
        axisBottom={{
          tickSize: 0,
          tickPadding: 16,
        }}
        axisLeft={{
          tickSize: 0,
          tickValues: 4,
          tickPadding: 16,
        }}
        gridYValues={4}
        theme={{
          tooltip: {
            chip: {
              borderRadius: '9999px',
            },
            container: {
              fontSize: '12px',
              textTransform: 'capitalize',
              borderRadius: '6px',
            },
          },
          grid: {
            line: {
              stroke: '#f3f4f6',
            },
          },
        }}
        tooltipLabel={({ id }) => `${id}`}
        enableLabel={false}
        role='application'
        ariaLabel='Cantidad de productos por categoría'
      />
    </div>
  )
}
